<template>
  <div class="user pt-5">
    <div class="pt-4 pl-4" v-if="!isHideBackProfileAction && isMobile">
      <router-link to="/user/account" class="inline-grid grid-flow-col justify-start items-center gap-2 font-semibold text-xl"><Chevron direction="left" width="16" height="16" />Back</router-link>
    </div>
    <div class="container py-8 mdmax:pt-4">
      <div class="flex justify-between gap-6 mdmax:flex-col">
        <div class="w-3/10 mdmax:hidden">
          <div class="sticky self-start top-32">
            <UserMenu />
          </div>
        </div>
        <div :class="[isMobile ? 'w-full px-4' : 'w-7/10']">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    UserMenu: () => import(/* webpackChunkName: "UserMenu" */ './components/UserMenu'),
    Chevron: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Chevron')
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    currentPath() {
      return this.$route.path
    },
    isHideBackProfileAction() {
      return this.currentPath === '/user/account' || this.currentPath === '/user/leave-request'
    }
  }
}
</script>
<style lang="scss" scoped>
.user {
  background-color: #f7f7f7;
  min-height: calc(100vh);
  @media screen and (max-width: 767px) {
    min-height: 0;
  }
}
</style>
